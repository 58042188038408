import * as React from "react";

function PodcastSVG(props) {
  return (
	<svg xmlns="http://www.w3.org/2000/svg" width={33.281} height={33.281} {...props}>
		<path d="M20,16.641a3.359,3.359,0,0,0-6.719,0A3.385,3.385,0,0,0,15,19.531v13.75h3.281V19.531A3.385,3.385,0,0,0,20,16.641Zm-3.359-10a10.006,10.006,0,0,0-6.8,17.344l2.422-2.344a6.641,6.641,0,1,1,8.75,0l2.422,2.344a10.006,10.006,0,0,0-6.8-17.344Zm0-6.641A16.633,16.633,0,0,0,5.156,28.672L7.5,26.328a13.267,13.267,0,0,1-4.219-9.687,13.359,13.359,0,0,1,26.719,0,13.267,13.267,0,0,1-4.219,9.688l2.344,2.344A16.633,16.633,0,0,0,16.641,0Z" fill="#0eb59a"/>
	</svg>


  );
}

export default PodcastSVG; 