import * as React from "react";

function IndividualSVG(props) {
  return (
	<svg xmlns="http://www.w3.org/2000/svg" width={34.74} height={29.84} {...props}>
		<path d="M34.74 27.75l-.03-.3-.22-.16a3.9 3.9 0 00-.17-1.35l.3-.14.03-.55-.32-.07-.01-.3.38-.21-.15-.43-.3.07a.99.99 0 00-1.14-.21l-.55-.05a.3.3 0 00-.02.03.73.73 0 00-.28-.1c-.28-.04-.53-.38-.69-.5-.1-.08-.15-.07-.19 0l-2.21-2.06a14.75 14.75 0 00-3.54-1.77 5.92 5.92 0 01-1.35-1.77 16.02 16.02 0 00-1.41-1.7l.23-.06a8.59 8.59 0 01-.64-.67c-.13-.18-.14-.46-.27-.57a1.58 1.58 0 01-.16-.88 2.24 2.24 0 00-.23-.96c-.12-.25-.05-.9-.6-1.26-.46-.46-1.84-1.73-2.23-2.2a30.03 30.03 0 01-1.8-2.61c-.5-.81-2.37-4.39-5.24-4.1 0 0-.25-.18-.36-.04l-.03.05-.5-.65s.03-2.46-2.33-2.2-1.76 2.53-1.65 2.86l.28.6-.21.76.46.04s.38.63.54.86.39.6.66.49.9-.3 1.1-.1a4.55 4.55 0 01.43.61.61.61 0 00-.09.32c.02.14.1.56.2 1a1.7 1.7 0 00-1.78-.22 1.59 1.59 0 00-.69 1.4l-.15.58c-.14-.07-.25.1-.25.1a3.03 3.03 0 00.25 1.37c.14.36.5 1.03.8 1 .09-.02.4-.04.82-.06a1.8 1.8 0 002 .38c.53.16 1.22.37 1.77.48a.07.07 0 000 .03l.05.12a5.71 5.71 0 00-2.68 1.18 11.3 11.3 0 01-1.18.73 2.64 2.64 0 00-1.63 1.8 5.51 5.51 0 01-.28 1.27 39.96 39.96 0 01-2.65 4.6l-.07-.07c-.2 0-.21.3-.35.33s-.21.36-.4.45a.62.62 0 00-.2.16c-.12 0-1.08.09-1.35.14a1.35 1.35 0 01-1.1-.36 1.59 1.59 0 00-1.05-.5c-.18.06-.23.49-.23.49l-.08.2.29.35h.14l.14.16-.1.27.26.24.22-.09.24.2-.12.27.42.35.3-.17a10.4 10.4 0 001.24.95l-.2.25.34.32.32-.11.25.14-.09.27.4.28.24-.27c.67-.07.88-1.23.9-1.45a.08.08 0 00-.02-.06 2.1 2.1 0 01.36-.54c.17-.12.17-.28.24-.48s.4-.3.48-.4c.05-.06.01-.13-.13-.25.34-.35.9-.94 1.3-1.3.58-.54 2.21-2.62 2.21-3.32a1.58 1.58 0 011.3-1.4c.47-.06 3.18-.6 4.33-.86.2.31.26.52.46.47a7.73 7.73 0 001.03-.3 3.5 3.5 0 01.63-.17 11.64 11.64 0 011.47 1.4c.05.16.21.13.38.07a17.85 17.85 0 001.56 1.6 22 22 0 012.27 1.84 4.12 4.12 0 002.03.99 24.45 24.45 0 013.44 1.53c.6.31 1.5.84 2 1.14a.74.74 0 01-.18.18.4.4 0 00.32.19c.25.03.4.32.53.39a4 4 0 01.53.4.25.25 0 00.08.39 3.75 3.75 0 011.08 1.5c.03.36.03 1.5.03 1.5s.51.8 1.08-.1a5.36 5.36 0 00.37-.63l.2-.04.07-.27-.16-.03a4.34 4.34 0 00.12-.6z" fill="#0eb59a"/>
    </svg>
  );
}

export default IndividualSVG;