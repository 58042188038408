import React from "react";

interface NotificationSettingsProps {
	
}
 
const NotificationSettings: React.FC<NotificationSettingsProps> = () => {
	return <div>Notification Settings</div>
}
 
export default NotificationSettings;