import * as React from "react";

function EyeSVG(props) {
  return (
	<svg xmlns="http://www.w3.org/2000/svg" width={31.18} height={21.02} {...props}>
		<path d="M6.13 2.92A16.32 16.32 0 0115.59 0a16.45 16.45 0 019.47 2.89 16.31 16.31 0 016.12 7.62 16.31 16.31 0 01-6.12 7.62 16.45 16.45 0 01-9.47 2.9 16.45 16.45 0 01-9.46-2.9A16.31 16.31 0 010 10.51a16.11 16.11 0 016.13-7.59zm4.45 12.53a6.84 6.84 0 005.01 2.1 6.83 6.83 0 005.01-2.1 6.75 6.75 0 002.09-4.94 6.76 6.76 0 00-2.09-4.94 6.83 6.83 0 00-5-2.09 6.84 6.84 0 00-5.02 2.09A6.76 6.76 0 008.5 10.5a6.75 6.75 0 002.1 4.94zM12.6 7.6a4.09 4.09 0 013-1.26 4.14 4.14 0 012.99 1.22 3.97 3.97 0 011.25 2.96 3.97 3.97 0 01-1.25 2.96 4.29 4.29 0 01-5.99 0 3.97 3.97 0 01-1.25-2.96A3.86 3.86 0 0112.6 7.6z" fill="#d6d6d6"/>
	</svg>
  );
}

export default EyeSVG; 