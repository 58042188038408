import * as React from "react";

function UnorderedListIcon(props) {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" 
		width={27.042} 
		height={20.281} 
		viewBox="0 0 27.042 20.281"
		{...props}
  	>
	  <path d="M7.887,11.268H25.915a1.127,1.127,0,1,0,0-2.254H7.887a1.127,1.127,0,1,0,0,2.254Zm0-9.014H25.915a1.085,1.085,0,0,0,1.127-1.127A1.085,1.085,0,0,0,25.915,0H7.887A1.085,1.085,0,0,0,6.761,1.127,1.085,1.085,0,0,0,7.887,2.254Zm0,18.028H25.915a1.127,1.127,0,1,0,0-2.254H7.887a1.127,1.127,0,1,0,0,2.254ZM.282.352A1.047,1.047,0,0,0,0,1.127,1.085,1.085,0,0,0,1.127,2.254,1.085,1.085,0,0,0,2.254,1.127,1.085,1.085,0,0,0,1.127,0,1.153,1.153,0,0,0,.282.352Zm0,9.014A1.047,1.047,0,0,0,0,10.141a1.085,1.085,0,0,0,1.127,1.127,1.127,1.127,0,1,0,0-2.254A1.153,1.153,0,0,0,.282,9.366Zm0,9.014A1.047,1.047,0,0,0,0,19.155a1.085,1.085,0,0,0,1.127,1.127,1.127,1.127,0,1,0,0-2.254A1.153,1.153,0,0,0,.282,18.38Z" 
	  fill="#3a3939"/></svg>
  );
}

export default UnorderedListIcon;