import * as React from "react";

function AssociationSVG(props) {
  return (
	<svg xmlns="http://www.w3.org/2000/svg" width={26.667} height={35} {...props}>
		<path id="medal_fill" data-name="medal fill" d="M13.333,8.333a12.993,12.993,0,0,1,6.733,1.833,13.245,13.245,0,0,1,4.767,4.767,13.281,13.281,0,0,1,0,13.467,13.245,13.245,0,0,1-4.767,4.767A12.993,12.993,0,0,1,13.333,35,12.993,12.993,0,0,1,6.6,33.167,13.245,13.245,0,0,1,1.833,28.4a13.282,13.282,0,0,1,0-13.467A13.245,13.245,0,0,1,6.6,10.167,12.993,12.993,0,0,1,13.333,8.333Zm0,5.833-2.2,4.467-4.933.7,3.567,3.5-.833,4.9,4.4-2.333,4.4,2.333-.833-4.9,3.567-3.5-4.933-.7ZM15,0h8.333V5L21.067,6.9A16.7,16.7,0,0,0,15,5.067ZM11.667,0V5.067A16.7,16.7,0,0,0,5.6,6.9L3.333,5V0Z" fill="#0eb59a"/>
	</svg>

  );
}

export default AssociationSVG; 