import * as React from "react";

function Arrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      {...props}
    >
      <path
        d="M23.313 10.673H5.432l6.962-7.535a1.719 1.719 0 00.528-1.284 1.721 1.721 0 00-.528-1.285 1.48 1.48 0 00-1.186-.57 1.482 1.482 0 00-1.187.57L.475 10.901a1.725 1.725 0 00-.422.77 1.654 1.654 0 000 .827 1.654 1.654 0 000 .827 1.728 1.728 0 00.422.771l9.546 10.331a1.483 1.483 0 001.187.571 1.481 1.481 0 001.186-.571 1.721 1.721 0 00.528-1.285 1.719 1.719 0 00-.528-1.284l-6.962-7.535h17.881a1.564 1.564 0 001.186-.541 1.828 1.828 0 00.5-1.285 1.826 1.826 0 00-.5-1.284 1.562 1.562 0 00-1.186-.54z"
        fill="#fff"
      />
    </svg>
  );
}

export default Arrow;