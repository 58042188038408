import * as React from "react";

function BoldIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={24}
      viewBox="0 0 20 24"
      {...props}
    >
      <path d="M17.711,11.55A8.653,8.653,0,0,0,18.8,9.506,6.544,6.544,0,0,0,19.212,7.2a6.91,6.91,0,0,0-2.12-5.1A6.968,6.968,0,0,0,12.008,0H0V24H12.8a6.919,6.919,0,0,0,5.1-2.119,7.2,7.2,0,0,0,1.482-8.006A7.268,7.268,0,0,0,17.711,11.55Zm-5.7-6.75a2.34,2.34,0,0,1,1.689.694,2.376,2.376,0,0,1,0,3.394,2.309,2.309,0,0,1-1.689.713H4.8V4.8ZM12.8,19.2H4.8V14.4H12.8A2.4,2.4,0,0,1,14.5,18.488,2.29,2.29,0,0,1,12.8,19.2Z" 
	  fill="#3a3939"/>
    </svg>
  );
}

export default BoldIcon;